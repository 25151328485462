import React from 'react';
import { Row, Col } from 'antd';
import '../../assets/css/patrocinadores.css';

import plata_1 from '../../assets/images/primera_2024/PATROCINADORES/millerknoll.png';
import plata_2 from '../../assets/images/segunda_2024/patrocinadores/logo_steelcase.png';

import oro_1 from '../../assets/images/primera_2024/PATROCINADORES/FINSA.png';
import oro_2 from '../../assets/images/segunda_2024/patrocinadores/logos_arcomurray.png';
import oro_3 from '../../assets/images/segunda_2024/patrocinadores/logos_isociundu.png';
import oro_4 from '../../assets/images/segunda_2024/patrocinadores/logo_hfa.png';
import oro_5 from '../../assets/images/segunda_2024/patrocinadores/energiareal.png';
import oro_6 from '../../assets/images/segunda_2024/patrocinadores/logos_FIBRAMTY.png';
import oro_7 from '../../assets/images/segunda_2024/patrocinadores/logo_cubimsa.png';
import oro_8 from '../../assets/images/segunda_2024/patrocinadores/logo_construlita.png';
import oro_9 from '../../assets/images/segunda_2024/patrocinadores/logo_cerform.png';
import oro_10 from '../../assets/images/segunda_2024/patrocinadores/logo_siila.png';
import oro_11 from '../../assets/images/segunda_2024/patrocinadores/logo_banorte.png';

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_patrocinadores.png';


let logos = [
    { tipo: 1, img_src: plata_1, nombre: 'millerknoll' },
    { tipo: 1, img_src: plata_2, nombre: 'steelcase' },

    { tipo: 2, img_src: oro_1, nombre: '' },
    { tipo: 2, img_src: oro_2, nombre: '' },
]


const CarouselPatrocinadores = (params) => {


    const plata = logos.filter(item =>{
      if(item.tipo === 1){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:12, offset:0}} md={{span:7}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
          </Col>
        )
    });
    const oro = logos.filter(item =>{
      if(item.tipo === 2){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:8, offset:0}} md={{span:7,offset:(logo.nombre === 'a' ? 2 : 0)}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
         
          </Col>
        )
    });

    return (
      <>
      <Row justify="center" className='back_blanco' style={{paddingBottom:'3%'}}>
        <Col xs={24} md={20}>
          <p className='textos_titulos_secciones color_azul'>PATROCINADORES</p>
        </Col>
        <Col xs={20}>
          <br /><br />
          <span className='patro_plata color_blanco back_gris_oscuro bold_font'>PLATINO</span>
          <br /><br />
        </Col>
        <Col xs={24} md={24}>
          <Row justify="center" align="">
            {plata}
          </Row>
        </Col>
        <Col xs={22}>
          <hr  style={{border:'none', height:'4px', backgroundColor:'#cccccc'}} />
        </Col>
        <Col xs={20}>
        <br /><br />
          <span className='patro_oro color_blanco back_dorado bold_font'>ORO</span>
          <br /><br />
        </Col>
        <Col xs={22} md={22}>
          <Row justify="center" align="middle">
            <Col xs={12} md={7}>
              <img src={oro_2} style={{width:'65%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_9} style={{width:'90%'}} />
            </Col>

            <Col xs={12} md={7}>
              <img src={oro_11} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_8} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_5} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_6} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_1} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_7} style={{width:'90%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_4} style={{width:'80%'}} />
            </Col>
            <Col xs={12} md={7}>
              <img src={oro_3} style={{width:'90%'}} />
            </Col><Col xs={12} md={7}>
              <img src={oro_10} style={{width:'80%'}} />
            </Col>
          </Row>
        </Col>
        <Col xs={22} md={22}>
          <Row justify="center" align="middle">
          
          </Row>
        </Col>
      </Row>
      </>
    );

};

export default CarouselPatrocinadores;
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/hospedaje.css';

import banner from '../../assets/images/tercera_reunion/HOSPEDAJE/hospedaje_titulo.png';

import oficial_sencilla from '../../assets/images/reunion_anual/HOSPEDAJE/hospedaje__info.jpg';
import agotado from '../../assets/images/segunda_2024/cupoagotado_ampip2024.jpg';

import costo_1 from '../../assets/images/segunda_2024/PNG/hospedaje_precio.png';

import img_titulo_hospedaje from '../../assets/images/reunion_anual/TITULOS/titulo_hospedaje.png';

const Hospedaje = (props) => {
    const [agenda, setRevista] = useState(false);
    const { idioma } = props;


    return (
        <Row className='back_hospedaje'>
            <Col xs={{span:24, offset:0}} md={{span:20, offset:2}} className=''>
                <Row justify='center' align='middle' style={{ position: '' }}>
                    
                    <Col span={24} offset={0} style={{ position: '', width: '100%' }}><br />
                        <p className="textos_titulos_secciones color_azul">HOSPEDAJE</p>
                    </Col>
                </Row>
            </Col>

            <Col xs={{span:22, offset:1}} md={{span:16, offset:4}}>
                <Row justify='center'>
                    <Col xs={22} md={20}>
                        <p className='color_azul text_safi'><span className='bold_font'>HOTEL MARRIOTT</span> - AGUASCALIENTES</p>
                        <p className='color_blanco text_safi_ubicacion'>BOULEVARD ZACATECAS NORTE S/N, COL. TROJES DE ALONSO, 20116 AGUASCALIENTES, AGS.
                        </p>
                       <br /><br />
                        <img src={agotado} style={{width:'100%'}} />
                    </Col>
                </Row>
            </Col>
            {/*<Col xs={{span:22, offset:1}} md={{span:24, offset:0}}>
                <Row justify={'center'}>
                    <Col xs={12} md={6}>
                        <img src={costo_1} className="img_100" />
                    </Col>
                </Row>
            </Col>
            
            <Col xs={{span:24, offset:0}} md={{span:20, offset:2}} style={{display:'non'}}>
                <p className="texto_hospedaje color_blanco ">
                <strong>Términos y condiciones</strong>
                <ul className='color_blanco'>
                    <li><span className="color_blanco">Tarifas en plan europeo sujetas al 16% de impuesto y al 3.5% de impuesto de hospedaje. Propinas no incluidas.</span></li>
                    <li><span className="color_blanco">Cancelación sin cargo hasta el 6 de junio de 2024, por lo tanto el hotel no reembolsará a partir de esta fecha, bajo ninguna circunstancia.</span></li>
                    <li><span className="color_blanco">Cualquier cambio en la reservación podría incurrir en ajustes en la tarifa.</span></li>
                    <li><span className="color_blanco">En caso de desear extender su reservación en fechas previas o posteriores, favor de contactar al equipo de reservaciones del hotel.</span></li>
                    <li><span className="color_blanco">AMPIP no realiza reservaciones de hotel.</span></li>
                    <li><span className="color_blanco">La reservación de habitación en el hotel es independiente del registro y eventual pago del evento.</span></li>
                </ul>
                </p>
                
                        
                    </Col>
                    <Col xs={{span:24, offset:0}} md={{span:20, offset:2}} className='back_azul'>
                        <Row justify={'center'} align={'middle'}>
                        <Col xs={20} md={16}>
                    <p className="texto_hospedaje_3 color_blanco">
                    <strong>MÉTODO DE RESERVACIONES</strong><br />
                    Las reservaciones serán realizadas y garantizadas de manera individual. 
                </p>
                    </Col>
                    <Col xs={{span:24, offset:0}} md={{span:8, offset:0}}>
                    <strong><a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1711488567685&key=GRP&app=resvlink" target="_blank" className="btn_patrocinio_hoome">Reserva tu tarifa AMPIP</a></strong>
                    </Col>
                  <Col xs={24} md={0}>
                        <br />
                    </Col>
                        </Row>
                    </Col>*/}  
           

        </Row>
    )
}
export default Hospedaje;
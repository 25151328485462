import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/costos.css';
import { FastBackwardOutlined, LeftOutlined } from '@ant-design/icons';

import img_titulo from '../../assets/images/reunion_anual/TITULOS/titulo_costos.png';

import costo_1 from '../../assets/images/segunda_2024/miembros ampip_ampip2024.jpg';
import costo_2 from '../../assets/images/segunda_2024/PNG/cuenta_mxn.png';
import costo_3 from '../../assets/images/segunda_2024/PNG/cuenta_usd.png';


const Costos = (props) => {
    const { idioma } = props;
    return (
        <Row justify="center" className='back_costos_sec back_azul'>
            <Col xs={0} md={24}>
                <br /><br /><br /><br />
            </Col>
            <Col xs={24} md={0}>
                <br /><br />
            </Col>
            <Col xs={24} md={20}>
                <p className="textos_titulos_secciones color_blanco">COSTOS</p>
            </Col>
            <Col xs={24}>
                <Row align={"bottom"} justify={"center"}>
                <Col xs={12} md={8}>
            <img src={costo_1} style={{width:'80%'}} />
                </Col>
            <Col xs={20} md={12}>
            <span className='no_ver_grande'><br /></span>
                <p className="texto_costos_grande color_blanco">2 entradas sin costo por corporativo</p><br />
                <p className="texto_costos_grande_2 color_blanco">Entradas adicionales:<br />
                $7,500.00 MXN por persona (450.00 USD)</p>
                </Col>
                </Row>
            </Col>
            <Col span={18}>
            <span className='no_ver_chico'><br /></span><br />
                <p className="texto_costos color_blanco">
                    <strong>Términos y condiciones</strong>
                    <lu className="color_morado">
                    <li><span className="color_blanco">Incluye kit de bienvenida y acceso a todos los eventos incluidos en el programa.</span></li>
<li><span className="color_blanco">No incluye transporte aéreo, ni hospedaje, ni transporte local</span></li>
<li><span className="color_blanco">La fecha límite para registro y beneficio de entradas sin costo es: miércoles 5 de junio de 2024</span></li>
<li><span className="color_blanco">La fecha límite para registro y pago de entradas adicionales es: miércoles 5 de junio de 2024</span></li>
<li><span className="color_blanco">No aplican reembolsos en caso de cancelación</span></li>
<li><span className="color_blanco">Para la solicitud de entradas sin costo se requiere el registro previo.</span></li>
<li><span className="color_blanco">Para la adquisición de entradas adicionales se requiere el registro previo y enviar un correo a <a href="mailto:ctoribio@ampip.org.mx" className='color_blanco' target='_blank'>ctoribio@ampip.org.mx</a>, indicando el número de entradas adicionales y adjuntando la cédula de identificación fiscal para su facturación.</span></li>
                    </lu>
                </p><br />
                <span className='no_ver_chico'><br /></span>
            </Col>
            
            <Col span={24} className='' style={{backgroundColor:'#ffffff'}}>
                <br />
                <Row justify="center">
                    <Col xs={22}>
                        <p className='datos_sub color_azul bold_font'><strong>DATOS BANCARIOS</strong></p>
                        <p className='transferen_text color_azul'>Transferencia electrónica, pago en una sola exhibición</p>
                        <br />
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col xs={20} md={10} className=''>
                    <img src={costo_2} style={{width:'100%'}} />
                    </Col>
                    <Col xs={0} md={0}>
                        <br /><br />
                    </Col>
                    <Col xs={20} md={10} className=''>
                    <img src={costo_3} style={{width:'100%'}} />
                    </Col>
                </Row>
                <br />
            </Col>
        </Row>
    )
}
export default Costos;
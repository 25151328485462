import React, { useState } from "react";
import { Row, Col } from "antd";
import "../../assets/css/membresia.css";

import registroCerradoImg from "../../assets/images/segunda_2024/registroagotado_ampip2024_movil.jpg";
import registroCerradoImgMovil from "../../assets/images/segunda_2024/registroagotado_ampip2024.jpg";

import { FastBackwardOutlined, LeftOutlined } from "@ant-design/icons";

const RegistroCerrado = (props) => {
  
  const { idioma } = props;

  return (
    <Row align="middle">
      <Col xs={0} md={24}>
        <img
            alt="Registro Cerrado"
            src={registroCerradoImgMovil}
            style={{ width: "100%" }}
          />
      </Col>
      <Col xs={24} md={0}>
        <img
            alt="Registro Cerrado"
            src={registroCerradoImg}
            style={{ width: "100%" }}
          />
      </Col>
    </Row>
  );
};
export default RegistroCerrado;

import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';


import usuario_img from '../../assets/images/evento/1_REGISTRO/icono-usuario.png';
import apellido_img from '../../assets/images/evento/1_REGISTRO/icono-password.png';
import img_entrar_recepcion from '../../assets/images/registro/registrate_masterclass_blanco.png';
import pago_paypal from '../../assets/images/registro/LOGO_paypal.png';

const RegistroCompleto = (props) => {

    const { correo, password, tipo_registro, nombre = '', idioma } = props;


    //let correo = 'martha@thethree.com';
    //let nombre = "Martha";
    

    useEffect(() => {
        scroller.scrollTo('header');
    }, []);

    return (
        <Row align="middle">
            <Col span={22} offset={1}>


                {/*tipo_registro === 'presencial' ?*/}
                
                <br /><br />
                <p className="textos_titulos_secciones color_blanco" style={{ textAlign: 'center' }}>¡Gracias por su registro!</p>
                
                    <p style={{ textAlign: 'center' }}>
                        <span className="texto-breve color_blanco"><strong>{nombre}</strong></span>
                        <br />
                        <span className="texto-breve color_blanco ">En breve recibirá un correo de confirmación a la siguiente dirección: </span><br /><br />

                        <span className="correo-azul color_blanco "> <strong>{correo}</strong> </span><br /> <br />
                        <span className="leyenda color_gris_claro ">Si no encuentra el mail de confirmación en su bandeja de entrada, es posible que esté en la <br />carpeta de SPAM / NO DESEADOS.</span> <br /> <br />
                    </p>
                    </Col>
                    <Col xs={{span:22, offset:1}} md={{span:12, offset:6}}>
                    <p className='back_morado'><br />
                        <span className="texto-breve color_blanco">
                            <strong>HOTEL MARRIOTT - AGUASCALIENTES</strong><br /></span>
                        <strong className="texto-breve2"><span>BOULEVARD ZACATECAS NORTE S/N, COL, <br />TROJES DE ALONSO, 20116 AGUASCALIENTES, AGS.</span></strong><br />
                        <strong className="texto-breve-direccion color_blanco">19 de junio al 21 de junio de 2024</strong>
                    <br /><br />
                    </p>
                    <p className='back_gris_oscuro color_blanco' style={{display:'none'}}>
                    <br />
                            <span className=''><strong>Acceso a la Primera Reunión AMPIP 2024</strong><br />El registro quedará confirmado una vez haya realizado el pago correspondiente.   <br />
                            Agradecemos envíe el comprobante de pago a:<br>
                            </br><strong>Ángela Cerón / <a className="color_blanco" target='_blank' href="mailto:aceron@ampip.org.mx">aceron@ampip.org.mx</a></strong></span>
                            <br /><br />
                            </p><br />
                            <h2 className='color_blanco' style={{textAlign:'justify'}}>Políticas de participación</h2>
                            <p className='color_blanco texto_politicas'>
                                <strong>Participantes: </strong>
                                Los eventos de AMPIP son exclusivamente para asociados y patrocinadores oficiales. Aplican máximo dos pases sin costo por corporativo. Pases adicionales tienen un costo de $7,500.00 pesos. Los pases adquiridos aplican únicamente para el evento que se trate y no se pueden tomar a cuenta de otros eventos.
                        <br /><br />
                        <strong>Confirmación: </strong>
                        Todos los registros el evento deberán realizarse en el sitio correspondiente y recibirán un mensaje vía correo electrónico, con las instrucciones correspondientes para asegurar su participación. En caso de dudas deberá comunicarlo a informacion@ampip.org.mx.
                        <br /><br />
                        AMPIP no realiza reservaciones de hotel. La reservación de habitación en el hotel es independiente del registro y eventual pago del evento.
                        <br /><br />
                        <strong>Grabación, fotografía y video: </strong>
                        <span>Los asistentes a las reuniones de asociados AMPIP, dan su consentimiento para aparecer en grabaciones de conferencias y materiales. Si tiene preguntas envíe un correo electrónico a <strong><a className='color_blanco' href="mailto:informacion@ampip.org.mx" target='_blank'>informacion@ampip.org.mx</a></strong>.</span>
                        
                        <br /><br />
                        <strong>Política de cancelación: </strong>
                        <span>Las cancelaciones de registro deberán hacerse por escrito (puede participar un sustituto del mismo corporativo, siempre y cuando sea registrado en las fechas límite establecidas).
                            <br />
                            Las actividades especiales pueden tener costo por separado; de ser el caso se informará oportunamente.
                            <br /><br />
                            No se realizarán reembolsos por cancelaciones o por no presentarse.
                            </span>
                        
                    </p>
                    <br /><br />
                </Col>
                    {/*:<>
                        
                        <p className="textos_titulos_secciones" style={{ textAlign: 'center' }}>{idioma === 'es' ? '¡Gracias por tu registro!' : 'Thank you for your registration!'}</p>
                        <br />
                        <p>

                            <span className="fondo back_azul texto_titulo_instruccions">{idioma === 'es' ? 'INSTRUCCIONES DE ACCESO' : 'ACCESS INSTRUCTIONS'}</span><br />

                        </p><br />

                        <Row >
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco"><strong>1. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }} >
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco">{idioma === 'es' ? 'Ingresa a' : 'Sign in to'} </span>
                                    <a className="datos-azul color_aqua" href='https://reuniondesocios.ampip.mx/transmision'>reuniondesocios.ampip.mx/transmision</a></p>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>2. </strong></span></p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Escribe tu <span className="datos-azul color_aqua "> usuario (correo electrónico)</span> en la casilla correspondiente.</span>
                                 : 
                                 <span className="datos-azul color_blanco">Write your <span className="datos-azul color_aqua "> username (email)</span> in the corresponding box.</span>}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>3. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Repite el paso anterior con tu <span className="datos-azul color_aqua "> primer apellido.</span></span>
                                :
                                <span className="datos-azul color_blanco">Repeat the previous step with your <span className="datos-azul color_aqua "> first last name.</span></span>}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>4. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Da clic en <span className="datos-azul color_aqua ">ingresar</span> y disfruta del evento.</span>
                                :
                                <span className="datos-azul color_blanco">Click <span className="datos-azul color_aqua ">enter</span> and enjoy the event.</span>}
                                    </p>
                            </Col>
                        </Row>



                        <br /><br />
                        <span className="fondo back_azul texto_titulo_instruccions">{idioma === 'es' ? 'TUS DATOS DE ACCESO' : 'YOUR ACCESS DATA'}</span>
                        <br /><br />

                        <Row align="middle">
                            <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 7 }} >
                                <p >
                                    <img src={usuario_img} style={{ width: '50px' }} />
                                </p>
                            </Col>
                            <Col xs={{ span: 16, offset: 0 }} md={{ span: 7, offset: 0 }}>
                                <p className="datos-azul color_blanco" style={{ textAlign: 'left' }}>
                                    {idioma === 'es' ? 'Tu usuario (correo electrónico):' : 'Your username (email):'}
                                    <br />
                                    <span><strong>{correo}</strong></span>
                                </p>
                            </Col>
                        </Row>


                        <Row align="middle">
                            <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 7 }}>
                                <p >
                                    <img src={apellido_img} style={{ width: '50px' }} />
                                </p>
                            </Col>
                            <Col xs={{ span: 16, offset: 0 }} md={{ span: 7, offset: 0 }}>
                                <p className="datos-azul color_blanco" style={{ textAlign: 'left' }} >
                                    {idioma === 'es' ?  'Tu primer apellido:' : 'Your first surname:'}
                                    <br />
                                    <span><strong>{password}</strong></span>
                                </p>
                            </Col>
                        </Row>



                        <br />
                        <p className="nota color_gris_oscuro ">{idioma === 'es' ? 'Nota: La transmisión se habilitará 30 minutos antes del evento.' : 'Note: The transmission will be enabled 30 minutes before the event.'}</p><br /> <br />
            </>*/}
                <Col span={22} offset={1}>
                <span className="color_blanco prximo_texto"><strong>{idioma === 'es' ? '¡NOS VEMOS PRONTO!' : 'WE WILL WAIT FOR YOU'}</strong></span>
                <br /><br />
                {/*<span className='leyenda'>Para cualquier información adicional, favor de dirigirse a Cecilia Toribio, Coordinadora de Eventos <a href="mailto:ctoribio@ampip.org.mx">ctoribio@ampip.org.mx</a></span>*/}
            </Col>
        </Row>
    );
}

export default RegistroCompleto;
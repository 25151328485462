import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_conferencistas.png';
import sergio_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Sergio_Argaelles_espanol.jpg";
import peter_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Peter_Linneman_Ingles.pdf";
import matt_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Matt_Brady_ingles.pdf";
import hamid_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Hamid_Moghadam_espanol.pdf";
import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/segunda_2024/ponentes/JorgeAvalos.jpg";
import ponente2 from "../../assets/images/segunda_2024/ponentes/MacarioSchettino.jpg";
import ponente3 from "../../assets/images/segunda_2024/ponentes/ShannonONeil.jpg";
import ponente4 from "../../assets/images/segunda_2024/ponentes/Jack-Fraker.jpg";

import ponente5 from "../../assets/images/segunda_2024/ponentes/AdrianCuello.jpg";
import ponente6 from "../../assets/images/segunda_2024/ponentes/AlfredoGiorgana.jpg";
import ponente7 from "../../assets/images/segunda_2024/ponentes/CarmenDePaula.jpg";
import ponente8 from "../../assets/images/segunda_2024/ponentes/EduardoGuemez.jpg";
import ponente9 from "../../assets/images/segunda_2024/ponentes/FotoMariuz.jpg";
import ponente10 from "../../assets/images/segunda_2024/ponentes/GumersindoCue_ampip.jpg";

const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Jorge Avalos",
    empresa: "Presidente de AMPIP",
    cargo: "",
    img: ponente1,
    url: sergio_con,
    semblanza: [<>
    Con más de 30 años de experiencia en el ámbito inmobiliario y financiero, Jorge Avalos es director general y cofundador de Fibra Monterrey empresa que realizó su oferta pública inicial en la Bolsa Mexicana de Valores, en diciembre del 2014 y cuya especialización es la propiedad y administración naves industriales primordialmente.  
    <br /><br />
    Es accionista y presidente de Escala. Empresa fundada en 1990 y pionera en la administración profesional de proyectos para el sector de bienes raíces en México. Actualmente, es el Presidente del Consejo Directivo de la Asociación Mexicana de Parques Industriales Privados (AMPIP). 
    <br /><br />
    Tiene una trayectoria de más de 20 años como miembro de distintos consejos de administración de empresas públicas y privadas. 
    <br /><br />
    Jorge es licenciado en contaduría del Instituto Tecnológico Autónomo de México (ITAM), con maestría en negocios en la Universidad de Dallas, diplomado de negocios por la Universidad de Harvard y programa de alta dirección D2 por el Instituto Panamericano de Alta Dirección (IPADE). 
    </>],
  },{
    nombre: "Macario Schettino",
    empresa: "",
    cargo: "",
    img: ponente2,
    url: sergio_con,
    semblanza: [<>
      Respetado analista económico-político, articulista, autor y comentarista en medios, reconocido tanto por la relevancia de su análisis del entorno nacional e internacional como por el estilo de las conferencias que imparte en México y el extranjero. 
      <br /><br />
      Actualmente es profesor-investigador de la Escuela de Gobierno del Tecnológico de Monterrey, en ciudad de México, y coordinador de Planeación del periódico El Universal. Anteriormente, se desempeñó como Coordinador de Planeación del Gobierno del Distrito Federal, así como asesor en organismos públicos, privados y sociales. 
      <br /><br />
      Macario Schettino escribe la columna "Fuera de la caja" en el periódico El Financiero, publica un podcast con ese mismo nombre cada semana, y ha colaborado con importantes medios como Milenio y El Universal, en esta última también fue Director de Negocios y Coordinador de Planeación. Participa en la mesa semanal Dinero y Poder en Canal 11. 
      <br /><br />
      Prolífico autor, Macario ha publicado decenas de libros, entre ellos: “Para Reconstruir México" “Donald Trump, el Aprendiz" y sus libros más recientes “El Fin de la Confusión" y "Economía en un día" ambos con la editorial Paidós. 
      <br /><br />
      Macario Schettino es Ingeniero Químico y de Sistemas por el ITESM- Campus Monterrey; Obtuvo la Maestría en Economía en el CIDE, y el Doctorado en Administración del ITESM-Programa Universidad de Texas en Austin. Y candidato al Doctorado en Historia por la Universidad Iberoamericana. 
      </>],
  },{
    nombre: "Shannon K. O'Neil",
    empresa: "",
    cargo: "",
    img: ponente3,
    url: sergio_con,
    semblanza: [<>Shannon K. O'Neil is the Vice President of Studies and Nelson and David Rockefeller senior fellow for Latin America Studies at the Council on Foreign Relations. She is an expert on global trade, supply chains, Mexico, Latin America, and democracy. 
    <br /><br />
    Dr. O’Neil is the author of The Globalization Myth: Why Regions Matter (Yale University Press, 2022), which chronicles the rise of three main global manufacturing and supply chain hubs and what they mean for U.S. economic competitiveness. She also wrote Two Nations Indivisible: Mexico, the United States, and the Road Ahead (Oxford University Press, 2013), which analyzes the political, economic, and social transformations Mexico has undergone over the last three decades and why they matter for the United States. She is a columnist for Bloomberg Opinion, and a frequent guest on national broadcast news and radio programs. Dr. O’Neil has often testified before Congress, and regularly speaks at global academic, business, and policy conferences. 
    <br /><br />
    Dr. O’Neil has lived and worked in Mexico and Argentina. She was a Fulbright scholar and a Justice, Welfare, and Economics fellow at Harvard University, and has taught Latin American politics at Columbia University. Before turning to policy, Dr. O'Neil worked in the private sector as an equity analyst at Indosuez Capital and Credit Lyonnais Securities. She holds a BA from Yale University, an MA in international relations from Yale University, and a PhD in government from Harvard University. She is the Chair of the board of directors of the Tinker Foundation. 
    </>],
  },{
    nombre: "Jack Fraker",
    empresa: "",
    cargo: "",
    img: ponente4,
    url: sergio_con,
    semblanza: [<>Jack Fraker is President, Global Head of Industrial & Logistics Capital Markets for Newmark. He is responsible for driving Newmark’s industrial & logistics business, primarily capital markets but also leasing. Throughout his career, Jack has been a consistent top national producer in industrial & logistics sales, representing some of the country’s largest corporations, global investors, pension fund advisors, REITs, and corporate tenants. Jack has personally completed over 1,800 industrial & logistics capital markets transactions totaling 1.5 billion square feet | $85 billion in more than 60 U.S. cities, Mexico, Europe, Japan, and South America. He has also advised on approximately 12,000 acres of development sites and over 300 office/industrial leasing assignments. As a partner with other firms prior to Newmark, Jack was cumulatively involved in over 3.3 billion square feet | $220 billion in various projects.
    <br /><br />
    <strong>Professional Achievements & Affiliations</strong>
    <ul>
    <li>Largest Transaction Award and other awards – SIOR</li>
<li>Lifetime Achievement Award, Dallas Business Journal 2014</li>
<li>Stemmons Service Award, No. Texas Commercial Real Estate, 2003</li>
<li>Lifetime Achievement Award – No. Texas Commercial Real Estate Assoc., 2024</li>
<li>Member, Society of Industrial and Office Realtors (SIOR)</li>
<li>Member, National Association of Investment and Office Properties (NAIOP)</li>
<li>Board of Governors, Urban Land Institute (ULI)</li>
<li>Member, Pension Real Estate Association (PREA)</li>
<li>Member, The Association of Foreign Investors in Real Estate (AFIRE)</li>
<li>Former Adjunct Professor | Board Member, Southern Methodist University (SMU)</li>
<li>Former Adjunct Professor | Board Member, The Univ. of Texas Business School</li>
<li>Current Board Member, Cristo Rey Dallas College Prep school.</li>
<li>Current | Former Board Positions – Hunt Southwest, Cushman & Wakefield, USAA</li>
<li>ealco), Metropolitan YMCA Dallas, and Girls Inc.</li>
    </ul>
    <strong>Education</strong>
    <br />
    Fraker earned a Bachelor of Business Administration at the University of Texas at Austin.
    </>],
  },{
    nombre: "Adrián Cuello Thomsen ",
    empresa: "",
    cargo: "",
    img: ponente5,
    url: sergio_con,
    semblanza: [<>Tiene más de 9 años de experiencia en soluciones de financiamiento como Arrendamiento, Factoraje, Crédito y Comercio Exterior.<br />
      Los últimos 3 años se ha enfocado en el desarrollo y ejecución de la estrategia de financiamiento sostenible para la Banca de Empresas y Gobiernos de BBVA, participando en el desarrollo de soluciones, propuesta de valor y desarrollo de negocio <br />
      Es Ingeniero Industrial egresado de la Universidad Iberoamericana </>],
  },{
    nombre: "Alfredo Giorgana",
    empresa: "",
    cargo: "",
    img: ponente6,
    url: sergio_con,
    semblanza: [<><strong>Responsibilidades:</strong><br />
      Alfredo Giorgana es actualmente Director Senior de Value & Risk Advisory en JLL Mexico y ha acumulado más de 30 años de experiencia in la elaboración de Valuación Inmobiliaria y estudios especiales como Mayor y Mejor Uso, y Estudios de Factibilidad de Mercado. ​
      <br /><br />
      <strong>Designaciones profesionales:</strong>
      <br />
      El Sr Giorgana es Ingeniero Civil graduado de las Universidad Nacional Autónoma de México (UNAM https://www.unam.mx/)  y es, además, Maestro en Ciencias en Ingeniería Civil de la Universidad de Minnesota (https://twin-cities.umn.edu/home), Valuador Acreditado Senior de la American Society of Appraisers, así como MRICS de la Royal Institution of Chartered Surveyors, y MAI del Appraisal Institute.​
      <br /><br />
      <strong>Sociedades profesionales:</strong>
      <br />
      Alfredo Giorgana es miembro del Colegio de Ingenieros Civiles de México, (https://cicm.org.mx/), así como del Instituto Mexicano de Ejecutivos de Finanzas ( www.imef.org.mx ) y del Urban Land Institute México (https://mexico.uli.org/?lang=es-mx). Durante el período 2004-2005 fue Presidente de la American Society of Appraisers Capítulo Mexico. ​
      <br /><br />
      <strong>Actividades académicas:</strong>
      <br />
      Alfredo Giorgana ha impartido clase en diferentes universidades como la UNAM y la  U Anáhuac y actualmente forma parte del claustro académico del Instituto Tecnológico Autónomo de México https://www.itam.mx/, la U Politécnica de Madrid https://www.upm.es/ y  Urban Land Institute México.​
      <br />
      Alfredo ha actuado como orador invitado y panelista en numerosos congresos y conferencias.  Así mismo, ha conducido y organizado eventos técnicos​</>],
  },{
    nombre: "Carmen Valéria De Paula",
    empresa: "",
    cargo: "",
    img: ponente7,
    url: sergio_con,
    semblanza: [<>Es Manager de Salud, Educación, Turismo, Comercio y Propiedades en América Latina y el Caribe, por parte del IFC. Hasta junio de 2023 lideró Desarrollo de Negocios para los sectores de salud y educación. Desde que se unió al IFC en 2009, Carmen ha promovido el desarrollo sostenible y la prestación generalizada de servicios de salud, ciencias biológicas y educación en toda la región. 
     <br /> <br />
      Antes de unirse al IFC, Carmen fue directora de UniCredit Group en Nueva York, donde trabajó durante más de nueve años en financiamiento de proyectos y estructuras de apalancamiento para el sector de infraestructura en las Américas.  
      <br /> <br />
      Carmen es licenciada en economía por la Universidad de Brasilia y tiene una maestría en política del programa de Economía Mundial de la London School of Economics and Political Science (LSE). </>],
  },{
    nombre: "Eduardo Güémez",
    empresa: "",
    cargo: "",
    img: ponente8,
    url: sergio_con,
    semblanza: [<>El Sr. Güémez es actualmente el CIO de Walton Street Capital México, donde administra todas las actividades de inversión para el fondo inmobiliario en México. Antes de esto, fue CIO de MRP y, de 2006 a 2015, fue CEO y Director General para LaSalle Investment Management, donde fue responsable de recaudar e invertir capital, así como de crear un equipo completamente operativo en México. Antes de unirse a LaSalle, fue Director General de Equidad en GE Real Estate en México, después de haber dirigido las operaciones de GE Equity en México, donde invirtió en diversas empresas, desde embotelladores de refrescos y minoristas especializados, hasta empresas de cosméticos y operadores de aeropuertos. 
    <br /> <br />
      Actualmente es miembro de la Junta Directiva de KidZania, Extended Suites y AMPIP (Asociación Mexicana de Parques Industriales). Se ha desempeñado como miembro de la junta directiva de empresas como Latinoamericana de Duty Free, Aeroplazas e Hipotecaria Casa Mexicana, Tradelossa, la Asociación Mexicana de Fondos de Capital Privado y la Fundación de Reintegración Social del Estado de México. 
      <br /> <br />
      El Sr. Güémez recibió una Maestría en Ciencias en Investigación de Operaciones de la Universidad de Stanford y tiene una licenciatura en Ingeniería Mecánica por parte del ITESM.</>],
  },{
    nombre: "Mariuz Calvet",
    empresa: "",
    cargo: "",
    img: ponente9,
    url: sergio_con,
    semblanza: [<>Es licenciada en Derecho y en Relaciones Internacionales con maestría en América Latina.  
    <br /> <br />
      Después de más de 18 años de trabajo en Sustentabilidad Corporativa, se ha especializado en el sector financiero, como Vicepresidente de Sustentabilidad en Citibanamex, Directora Ejecutiva de Sustentabilidad e Inversión Responsable en Grupo Financiero Banorte, Directora de Finanzas Sustentables para México y América Latina en HSBC y hoy funge como Chief Sustainability Officer de Banco Santander México. Presidió el Comité ESG de la Bolsa Mexicana de Valores y hoy preside la Comisión ASG de la Asociación de Bancos de México.  
      <br /> <br />
      Fue parte del Grupo de fundadores de los Principios de la Banca Responsable de las Naciones Unidas y Co Presidente del Grupo de trabajo global creador del marco de reporteo global de riesgos de naturaleza, TNFD, por sus siglas en inglés. </>],
  },{
    nombre: "Gumersindo Cué Aguilar",
    empresa: "",
    cargo: "",
    img: ponente10,
    url: sergio_con,
    semblanza: [<>Gumersindo Cué Aguilar cuenta con más de 20 años de experiencia en el sector energético, incluyendo el Instituto Mexicano del Petróleo (IMP), Secretaría de Energía (SENER) y Comisión Reguladora de Energía (CRE) Actualmente es Director Ejecutivo de la Asociación Mexicana de Energía (AME), la cual agrupa a las principales empresas privadas de energía eléctrica en México, que representan más del 40% de la capacidad instalada en el Sistema Eléctrico Nacional. 
    <br /> <br />
    En la SENER se desempeñó en las subsecretarías de Planeación Energética, Hidrocarburos y Electricidad, siendo Director General de Seguimiento y Coordinación de la Industria Eléctrica y teniendo a su cargo la coordinación de la implementación de subastas eléctricas de mediano plazo, análisis de insumos económicos para las subastas de largo plazo, así como el seguimiento de los proyectos de centrales limpias en desarrollo.  
    <br /> <br />
    En cooperación internacional, desde la SENER impulsó la adhesión de México como primer miembro latinoamericano de la Agencia Internacional de Energía (IEA), así como la revisión de pares de publicaciones insignia como el World Energy Outlook y Offshore Energy Outlook. 
    <br /> <br />
    Gumersindo Cué es consultor en economía de la energía, transición energética, cambio climático, mercados eléctricos y de petrolíferos, para el Instituto Nacional de Ecología y Cambio Climático (INECC), Programa de Naciones Unidas para el Desarrollo (PNUD), Banco Interamericano de Desarrollo, así como empresas privadas para la importación de gasolinas y diésel. 
    <br /> <br />
    Gumersindo Cué es Licenciado en Economía por el Instituto Politécnico Nacional, actualmente cursa el programa MSc Sustainable Business Leadership de la Universidad de Leeds del Reino Unido, y es miembro del Priestley Centre for Climate Futures de dicha universidad. 
    <br /> <br />
    Ha sido profesor invitado en el Programa Ejecutivo de Inversiones en Energía, así como en el Diplomado de Economía de la Energía del Instituto Tecnológico Autónomo de México (ITAM), y ponente en el Programa de Especialización en Derecho Energético, de la Universidad Nacional Autónoma de México (UNAM). En ambos casos, impartiendo módulos de mercados eléctricos y mercado de petrolíferos. </>],
  },
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };
//sort acomoda alfabeticamente
  let ver_ponentes = expositor.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_blanco bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_blanco"
              >
                Leer semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <p className="textos_titulos_secciones color_azul">CONFERENCISTAS</p><br />
        </Col>
        <Col xs={10} md={16}>
       <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_ponentes}
        </Slider>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;

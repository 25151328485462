import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_agenda.png';

import clinica_golf from '../../assets/documentos/Clinica_de_golf.pdf';
import info_pdf from '../../assets/documentos/Torneo_de_golf.pdf';
import agotado from '../../assets/images/segunda_2024/gracias_lugaresagotados_ampip2024.jpg';

import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(1);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 1,
      hora: "16:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Sesión del Consejo Directivo</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="color_morado">Salón Catrina</span>],
        },
      ],
      back: "",
    },{
      dia: 4,
      hora: "18:00 - 22:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cena de bienvenida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className=""><i>Foyer</i> del Salón Astor</span></>],
        },
      ],
      back: "back_dispar",
    },{
      dia: 1,
      hora: "19:00 - 22:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cena de bienvenida</span>],
              cargo: [<span><strong className="color_morado">Jorge Avalos,</strong> Presidente AMPIP<br />
              <strong className="color_morado">Teresa Jiménez,</strong> Gobernadora del Estado de Aguascalientes</span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="color_morado_3">Palacio de Gobierno</span>],
        },
      ],
      back: "back_dispar",
    },{
      dia: 2,
      hora: "08:00 - 08:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Registro y networking</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },{
      dia: 2,
      hora: "",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span className="color_morado">Gran salón San Marcos</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar_3",
    },
    {
      dia: 2,
      hora: "08:30 - 08:50 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Bienvenida a la Segunda Reunión AMPIP 2024. Informe de actividades</span>],
              cargo: [<><span className="color_morado"><strong>Jorge Avalos,</strong></span> Presidente de la AMPIP</>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "08:50 - 09:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Mapa de Ruta</span>],
              cargo: [<><span className="color_morado"><strong>Claudia Esteves,</strong></span> Directora General de la AMPIP</>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "09:00 - 10:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Tendencias políticas postelectorales en México: reflexiones sobre el futuro</span>],
              cargo: [<><span className="color_morado"><strong>Macario Schettino,</strong></span> Analista político</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "10:00 - 11:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>El papel de México en la dinámica del mercado global de capital industrial</span>],
              cargo: [<><span className="color_morado"><strong>Jack Fraker,</strong></span> Presidente de Global Head of Industrial & Logistics Dallas, Newmark</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "11:00 - 11:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso networking</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "11:30 - 12:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>El panorama energético en México: prioridades y planes del nuevo gobierno</span>],
              cargo: [<><strong className="color_morado">Gumersindo Cue</strong>, Director General de la Asociación Mexicana de Energía</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "12:30 - 13:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<><span>Financiamiento verde: impulsando parques industriales sostenibles hacia el futuro</span></>],
              cargo: [<><span className=""><strong>Panelistas:</strong></span><br />
              <span className="color_morado"><strong>Carmen Valéria De Paula,</strong></span> Gerente de Salud, Educación, Turismo, Comercio Minorista y Propiedades – LAC - International Finance Corporation<br />
              <span className="color_morado"><strong>Adrián Cuello,</strong></span> Director Sustainable Finance – Enterprises - BBVA<br />
              <span className="color_morado"><strong>Mariuz Calvet,</strong></span> Chief Sustainability Officer - Santander<br />
              <span className="color_morado"><strong>Alfredo Giorgana,</strong></span> Senior Director Value & Risk Advisory – JLL
              <br /><br />
              <span className=""><strong>Moderador:</strong></span><br />
              <span className="color_morado"><strong>Eduardo Güémez,</strong></span> Senior Principal - Walton Street Capital Mexico</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "13:30 - 14:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Geopolítica e integración relacional: perspectivas para México</span>],
              cargo: [<span><strong className="color_morado">Shannon K. O’Neil,</strong> Vice President of Studies and Nelson and David Rockefeller Senior Fellow for Latin American Studies at the Council on Foreign Relations</span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "14:30 - 14:40 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Conclusiones y cierre del evento</span>],
              cargo: [<><strong className="color_morado">Jorge Avalos,</strong> Presidente AMPIP</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "14:40 - 16:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comida networking</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<span className="color_morado_2">Jardín</span>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "16:00 - 16:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "16:00 - 16:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Traslado al Cortijo Manuel Espinosa “Armillita" (vestimenta casual y cómoda)</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [''],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "17:00 - 22:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Experiencia taurina, visita al Museo Taurino y cena</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 5,
      hora: "09:00 – 12:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Visita Temática</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2"></span></>],
        },
      ],
      back: "",
    },
    {
      dia: 4,
      hora: "09:15 – 09:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Traslado a la planta de Nissan</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2"></span></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 4,
      hora: "09:45 – 11:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Recorrido por proceso productivo de NIssan</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 4,
      hora: "11:45 – 12:05 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Traslado al aeropuerto internacional de Aguascalientes</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 4,
      hora: "12:005 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Fin de la visita</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    }
    
  ];

  const ordenes = [
    {orden: 1, texto:[<>
    <p className="titulo_agenda">Comité de Promoción</p>
    <ol className="desc_agenda" type="1">
      <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">José Luis Benitez</span></li>
      <li>Autopresentación de los asistentes</li>
      <li>Presentación de plan de trabajo y acciones relevantes
        <ol type="a">
          <li>Plan de trabajo 2022</li>
        </ol>
      </li>
      <li>Seguimiento y avance de acuerdos
        <ol type="a">
          <li>Información
            <ol type="i">
              <li>Materiales actualizados</li>
              <li>Sitio web</li>
            </ol>
          </li>
          <li>Resumen de leads AMPIP</li>
          <li>Ecosistema de promoción AMPIP</li>
          <li>Eventos
            <ol type="i">
              <li>Promoción con cuerpo diplomático - 20 de octubre 2022</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Nuevos temas:
        <ol type="a">
          <li>Encuesta sobre percepción y tendencias de la IED en México, 2022 - 2023</li>
        </ol>
      </li>
      <li>Propuestas de eventos y capacitación</li>
    </ol>
    </>]},
    {orden: 2, texto:[<>
      <p className="titulo_agenda">Comité ASG</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">Karen Mora</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Presentación nuevo Secretario Técnico - <span className="color_azul_claro_2">Diego Colunga</span></li>
        <li>Presentación de plan de trabajo y acciones <span className="color_azul_claro_2">relevantes – Karen Mora</span>
          <ol className="q">
            <li>Plan de trabajo 2022</li>
            <li>Acciones relevantes
              <ol type="i">
                <li>Resumen del Sondeo ASG y Sustentabilidad</li>
                <li>Adhesión AMPIP al Pacto Mundial</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Seguimiento y avance de acuerdos
          <ol type="a">
            <li>Perfil de puesto responsable ASG en parques (técnico y directivo) - <span className="color_azul_claro_2">Mario del Bosque</span></li>
            <li>Guía para homologación de conocimientos - <span className="color_azul_claro_2">Carlos Viesca</span></li>
            <li>Directorio - mapa del ecosistema AMPIP - <span className="color_azul_claro_2">Diego Colunga</span></li>
            <li>Reporte de los seminarios:
              <ol>
                <li>Certificaciones Leed, Well, Edge - <span className="color_azul_claro_2">Karen Mora</span></li>
                <li>Código de red - <span className="color_azul_claro_2">Diego Colunga</span></li>
                <li>Programa Nacional de Auditoría Ambiental PROFEPA - <span className="color_azul_claro_2">Rodolfo Morales</span></li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Nuevos temas:
          <ol>
            <li>Presentación proyecto Mariposa Monarca – <span className="color_azul_claro_2">David Eaton</span> - 15 min</li>
            <li>Compromiso social AMPIP – <span className="color_azul_claro_2">Karen Mora</span> – 5 min
              <ol>
                <li>Se reciben propuestas hasta el 15 de noviembre</li>
                <li>Reunión noviembre, Comité ASG decide acción social para 2023</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </>]},
    {orden: 3, texto:[<>
      <p className="titulo_agenda">Comité de Infraestructura y Seguridad</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité - <span className="color_azul_claro_2">Daniel Jaimes</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Seguimiento y avance de acuerdos - <span className="color_azul_claro_2">Lizbeth Contreras</span>
          <ol type="a">
            <li>Reporte general de las reuniones de trabajo del Comité en el periodo mayo-agosto</li>
            <li>Presentación de la nueva versión del documento “Criterios mínimos en seguridad con los que deben contar los parques industriales” (antes Parque Industrial Seguro)</li>
            <li>Presentación del directorio con nuevos consultores expertos en materia de seguridad</li>
            <li>Estatus de la actualización de la Norma Mexicana de Parques Industriales
              <ol type="i">
                <li>Situación actual del comité (CTNNPI)</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Logística
          <ol type="a">
            <li>Sugerencias de lineas de acción por parte de los asistentes</li>
            <li>Actividades sugeridas</li>
          </ol>
        </li>
        <li>Propuestas de eventos y capacitación</li>
      </ol>
      </>]}
  ]

  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row
          
          align="middle"
          style={{ padding: "15px 0px" }}
          className={da.back}
        >
          <Col xs={{span:24, offset:0}} md={{span:da.back === 'back_dispar_3' ? 0 : 4, offset:0}}>
            {da.hora != '' ? <><p className="hora_agenda">{da.hora}</p><br /></> : <></>}
            
          </Col>
          <Col xs={{span:24, offset:0}} md={{span:da.back === 'back_dispar_3' ? 24 : 17, offset:da.back === 'back_dispar_3' ? 0 : 3}}>
            {da.actividad.map((datos) => {
              return (
                <>
                  <Row justify="center" align="middle">
                    <Col xs={24} md={24} style={{ paddingLeft: "10px" }}>
                      {datos.personas != "" ? (
                        <>
                          {datos.personas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={24} md={24}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                    {datos.desc != "" && (
                        <p className="desc_agenda">{datos.desc}</p>
                      )}
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      );
    });

    let ver_texto_modal = ordenes
    .filter((item) => {
      if (ver_order === item.orden) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          {seccion.texto}
        </Col>
      );
    });

  return (
    <Row className="back_titulo_agenda">
      <Col span={20} offset={2}>
        <Row justify="center" align="middle" style={{ position: "" }}>
          <Col
            span={24}
            offset={0}
            style={{ position: "", width: "100%", paddingTop:'3%' }}
          >
            <p className="textos_titulos_secciones color_azul">PROGRAMA</p>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda color_azul">
          <strong>HORARIO </strong> CDMX / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
          </p><br />
        </Col>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        <Row justify="space-around">
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(1)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 1 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"}>
            <p className={dia === 1 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
              <strong>Día 01</strong>
              <br />
              <span className={dia === 1 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>19 junio, 2024</span>
            </p>
            </div>
          </Col>
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(2)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 2 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"
            }>
            <p className={dia === 2 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
              <strong>Día 02</strong>
              <br />
              <span className={dia === 2 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>20 junio, 2024</span>
            </p>
            </div>
          </Col>
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span:8, offset: 0 }}
            onClick={() => setDia(3)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 3 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"
            }>
            <p className={dia === 3 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
              <strong>Día 03</strong>
              <br />
              <span className={dia === 3 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>21 junio, 2024</span>
            </p>
            </div>
          </Col>
        </Row><br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        {dia === 3 ? <><p className="txt_cabos_agenda color_azul"><strong>09:00 – 14:00 Hrs. - Visitas temáticas</strong><br /></p>
        <Row justify={'center'}>
          <Col xs={24} md={24}>
          <img src={agotado} className="img_100" />
          </Col>
          </Row></> : <></>}
        {visualizar_agenda}

        <br />
        <br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda color_azul">
          <strong>HORARIO </strong> CDMX / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
          </p><br /><br />
        </Col>
        <Col xs={24} className="back_morado" style={{padding:'2px'}}>
          
        </Col>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </Row>
  );
};
export default Agenda;

import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '../../assets/css/evento.css';

import ima from '../../assets/images/temp/50x50.png';
import logo_ampip from '../../assets/images/segunda_reunion/logoampip.png';
import ima2 from '../../assets/images/temp/50x50.png';
import { Auth } from '../../utils/AuthContext';
import Login from '../registro/Login';


const secciones = [
  { nombre: 'AGENDA', url: '/#agenda', nombre_en: 'SCHEDULE' },
  { nombre: 'COSTOS', url: '/costos', nombre_en: '' },
  { nombre: 'CONFERENCISTAS', url: '/#conferencistas', nombre_en: '' },
  { nombre: 'PATROCINADORES', url: '/#patrocinadores' },
  { nombre: 'HOSPEDAJE', url: '/#hospedaje' },
  { nombre: 'MEMBRESÍA', url: '/#membresia', nombre_en: 'MEMBERSHIP' },
  { nombre: 'DIRECTORIO', url: '/directorio', nombre_en: '' },
  //{ nombre: 'CONFERENCISTAS', url: '/#conferencistas', nombre_en: '' },
  //{ nombre: 'GRUPO ANFITRIÓN', url: '/grupo-anfitrion', nombre_en: '' },
  { nombre: 'REGISTRO', url: '/registro', nombre_en: 'REGISTER' },
  //{nombre:'TRANSMISIÓN', url:'/transmision'},
];

const success = () => {
  mensaje('success', 'Bienvenido', '');
};


const mensaje = (tipo, titulo, texto) => {

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

const Header = (props) => {

  const { usuario } = useContext(Auth);
  const [modal_entrar, setModalEntrar] = useState(false);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_submenu, setMostrarSubmenu] = useState(false);
  const { setIdioma, idioma } = props;

  const [scroll, setScroll] = useState(0);
  const [ver_color, setVerColor] = useState(true);
  const [ver_lugar, setVerLugar] = useState(false);

  useEffect(() => {
    if (usuario) {
      setMostrarLogin(false);
    } else {

      setMostrarLogin(false);//cambiar a true
    }
  }, [usuario]);



  let location = useLocation();

  const [redireccionar_en_vivo, setRedireccionarEnVivo] = useState(false);


  useEffect(() => {
    if (redireccionar_en_vivo) {
      window.location.href = "/transmision";
    }
  }, [redireccionar_en_vivo]);

  useEffect(() => {
    if (location.pathname === '' || location.pathname === '/' || location.pathname === '/registro' || location.pathname === '/costos' || location.pathname === '/transmision') {
      setVerLugar(false)
    } else {
      setVerLugar(true)
    }

  }, [location]);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
      setVerColor(scrollCheck);
    })
  }, [])

  let visualizar_secciones = secciones.map((seccion) => {

    if (seccion.url === '/transmision') {
      if (!usuario) {
        return (
          <>

          </>
        );
      }
    }


    return (
      <Link className="titles" to={seccion.url}>
        <span className={location.pathname + '' + location.hash === seccion.url || location.pathname === seccion.url ? "seccion_sel " : "seccion "} >
          {idioma === 'es' ? seccion.nombre : seccion.nombre_en}
        </span>
      </Link>
    );
  });

  return (
    <>


      <Row justify="" align="middle" className={ver_lugar === true ? (ver_color === true ? 'back_menutop_membresia' : 'back_menu_scroll') : (ver_color === true ? 'back_menu' : 'back_menu_scroll')} style={{ paddingTop: '10px', paddingBottom: '10px', position: 'fixed', zIndex: '99', width: '100%' }}>

        <Col xs={{ span: 7, offset: 0 }} md={{ span: 4, offset: 0 }} style={{ padding: '0px' }}>

          <img alt="logo" style={{ width: '80%' }} src={logo_ampip} />


        </Col>

        {location.pathname !== '/visualizar-preguntas' &&

          <>
            <Col xs={{ span: 5, offset: 4 }} md={{ span: 3, offset: 0 }} style={{ display: "none" }}>
              <p style={{ margin: '0px', cursor: 'pointer', display: 'none' }}>
                <span
                  className={idioma === "es" ? "seccion_sel" : "seccion"}
                  onClick={() => setIdioma("es")}
                >
                  ESPAÑOL
                </span>
                <span className="seccion"> | </span>
                <span
                  className={idioma === "en" ? "seccion_sel" : "seccion"}
                  onClick={() => setIdioma("en")}
                >
                  ENGLISH
                </span>
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 18, offset: 0 }} style={{ textAlign: 'right' }}>

              {visualizar_secciones}

              {!usuario ?
                <Link className="titles" onClick={() => { setMostrarLogin(true) }} to="" style={{ display: 'none' }}>
                  <span className={location.pathname + '' + location.hash === '/transmision' || location.pathname === 'transmision' ? "seccion_sel " : "seccion "}>
                    TRANSMISIÓN
                  </span>
                </Link>
                :
                <Link className="titles" to="/transmision" style={{ display: 'none' }}>
                  <span className={location.pathname + '' + location.hash === '/transmision' || location.pathname === 'transmision' ? "seccion_sel " : "seccion "}>
                    TRANSMISIÓN
                  </span>
                </Link>
              }

              <span className="cerrar_chico" style={{ display: 'non' }}>
                {usuario ?
                  (
                    <Link className="titles" to="/cerrar-sesion">
                      <span className="seccion">
                        CERRAR
                      </span>
                    </Link>
                  )
                  :
                  (
                    <Link className="titles" onClick={() => { setMostrarLogin(true) }} to="" style={{ display: 'none' }}>
                      <span className="seccion Century Gothic Regular" >
                        INICIAR SESIÓN
                      </span>
                    </Link>
                  )
                }
              </span>

              <Link className="titles_registro boton_registrar_header " to="/registro" style={{ display: 'none' }}>
                REGISTRO
              </Link>

            </Col>

            <Col xs={{ span: 6, offset: 9 }} lg={{ span: 0, offset: 0 }} style={{ textAlign: 'right' }}>

              <MenuOutlined onClick={() => { setMostrarSubmenu(!mostrar_submenu) }} style={{ padding: '0px', fontSize: '20px', color: 'white' }} />

            </Col>
            {mostrar_submenu &&
              <Col xs={{ span: 24 }} lg={{ span: 0 }} style={{ display: 'non', background: '#101010' }}>

                <>
                  <p >
                    <Link to="/#agenda" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      AGENDA
                    </Link>
                  </p>
                  <p style={{ display: 'non' }}>
                    <Link to="/costos" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      COSTOS
                    </Link>
                  </p>
                  <p style={{ display: 'none' }}>
                    <Link to="/#conferencistas" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      CONFERENCISTAS
                    </Link>
                  </p>
                  <p style={{ display: 'non' }}>
                    <Link to="/#patrocinadores" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      PATROCINADORES
                    </Link>
                  </p>
                  <p style={{ display: 'non' }}>
                    <Link to="/#hospedaje" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      HOSPEDAJE
                    </Link>
                  </p>
                  <p style={{ display: 'non' }}>
                    <Link to="/#membresia" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      MEMBRESÍA
                    </Link>
                  </p>
                  <p style={{ display: 'none' }}>
                    <Link to="/#conferencistas" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      CONFERENCISTAS
                    </Link>
                  </p>
                  
                  <p style={{ display: 'non' }}>
                    <Link to="/registro" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      REGISTRO
                    </Link>
                  </p>
                  <p style={{ display: 'none' }}>

                    {usuario ?
                      <Link to="/transmision" onClick={() => { setMostrarSubmenu(false) }} className="seccion" style={{ display: 'non' }}>
                        TRANSMISIÓN
                      </Link>
                      :
                      <Link onClick={() => { setMostrarLogin(true); setMostrarSubmenu(false); }} to="" className="seccion" style={{ display: 'non' }}>
                        TRANSMISIÓN
                      </Link>
                    }

                  </p>

                  <p style={{ display: 'none' }}>
                    <Link to="/registro" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                      REGISTRO
                    </Link>
                  </p>


                  <p style={{ display: 'non' }}>
                    {usuario ?
                      (
                        <Link to="/cerrar-sesion" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                          CERRAR
                        </Link>
                      )
                      :
                      (
                        <Link onClick={() => { setMostrarLogin(true); setMostrarSubmenu(false); }} to="" className="seccion" style={{ display: 'none' }}>
                          INICIAR SESIÓN
                        </Link>
                      )
                    }
                  </p>

                  <p style={{ display: 'none' }} className="back_gris_reunion_oscuro">
                    <Link to="/registro" onClick={() => { setMostrarSubmenu(false) }} className="seccion_movil_registro Century Gothic Regular">
                      REGISTRO
                    </Link>
                  </p>

                </>





              </Col>}
          </>
        }
        <Modal
          open={modal_entrar}
          title=""
          onCancel={() => { setModalEntrar(false); }}
          footer={null}
        >
          {modal_entrar &&
            <div></div>
          }
        </Modal>
      </Row>



      <Modal
        title="INICIA SESIÓN"
        open={mostrar_login}
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
      >
        <Login onResponse={() => { setMostrarLogin(false); setRedireccionarEnVivo(true); }} onClick={success} />
      </Modal>

    </>
  );
}

export default Header;
import React, {useState, useEffect} from 'react';
import { Row, Col, Pagination } from 'antd';
import '../../assets/css/networking.css';
import { networking } from '../../utils/ConexionServidor';

const pageSize = 1;

  const MyPagination = ({ total, pageSize, onChange, current }) => {

    console.log();

    return (
      <Pagination
      pageSizeOptions={[10]}
        onChange={(e)=>onChange(e)}
        total={total}
        current={current}
        pageSize={pageSize}
        size="small"
        defaultPageSize={1}
      />
    );
    
  };
  

const Cards = (props) =>{

  const {categoria} = props;
  const [current, setCurrent] = useState(1);
  const [personas, setPersonas] = useState([]);
  const [por_pagina, setPorPagina] = useState(10);

  useEffect(()=>{
    networking().then(data => {
      switch(data.status){
        case 0:
          setPersonas(data.data);
          console.log(data.data);
        break;
        default:
        break;
      }
    });
  },[]);

  useEffect(()=>{
    setCurrent(1);
  },[categoria])

  let ver_personas = personas.filter(persona =>{
      if(persona.tipo === categoria || categoria === 'todos'){
          return true
      }
    return false;
  });

  console.log(categoria);
  console.log(ver_personas);

  return(
    <>
    
    <Row>
      <Col span={24}>
      <Row>
      { ver_personas.map((persona, index)=>{

        if(((current*por_pagina)-por_pagina) <= index && index < (current*por_pagina)){
          return(
            <Col xs={{span:20, offset:2}} md={{span:10, offset:1}} className="contnedos_card_net" >
              <p className="nombre_net color_azul">{persona.nombre} {persona.apellido}</p>
              <p className="empresa_net">{persona.procedencia}</p>
              <p className="cargo_net"><em>{persona.correo_p}</em></p><br />
              <p className="perfil_net_p"><span className="perfil_net back_morado">{persona.tipo === 'Socio' ? 'Membresía' : persona.tipo}</span></p>
            </Col>
          );
        }else{
          return(
            <Col xs={{span:20, offset:2}} md={{span:10, offset:1}} className="contnedos_card_net" style={{display:'none'}} >
              <p className="nombre_net">{persona.nombre} {persona.apellidos}</p>
              <p className="empresa_net">{persona.empresa}</p>
              <p className="cargo_net"><em>{persona.cargo}</em></p><br />
              <p className="perfil_net_p"><span className="perfil_net">{persona.tipo}</span></p>
            </Col>
          );
        }
  })}
      </Row>
      </Col>
      <Col span={24}>
      
      <MyPagination total={ver_personas.length} pageSize={por_pagina} current={current} onChange={(e) => { setCurrent(e); }}/>

      </Col>
    </Row>
    </>
  );
}

export default Cards;